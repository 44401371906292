import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";
import { SignOutForm } from "./components";
import { getUserHeardList } from "./constants/functions";
import { isUserLogin } from "./constants/methods";
import { useScreenSize } from "./hooks";
import { LogInBox } from "./modules";

function App({ container }) {
  const visible = container.getAttribute("visible");
  const [isVisible, setIsVisible] = useState(visible);
  const [isSignOut, setIsSignOut] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [userData, setUserData] = useState(null);
  const [countClick, setCountClick] = useState(0);

  // useEffect(() => {
  //   console.log(isVisible, "isVisible");
  //   // if (isVisible == "true") observer.disconnect();
  // }, [isVisible]);

  const mutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (
        mutation.type !== "attributes" ||
        mutation.attributeName !== "visible"
      ) {
        return;
      }
      setIsVisible(
        // prev_value: mutation.oldValue,
        mutation.target.getAttribute("visible")
      );
    }
  };

  document.addEventListener("RTUnlockPremiumFeatures", function (event) {
    setIsVisible("true");
  });

  const isUserLogined = isUserLogin();

 const signOutClick = () => {
    // if (!isUserLogined) {
    //   setIsVisible("true");
    // } else {
      axios
        .get("/me")
        .then((res) => {
          if (res.data.success) {
            // setIsSuccess((prev) => !prev);
            if (isSignOut === true) { setIsSignOut(prev=>!prev); return null}
            const headers = new Headers({
              Authorization: `Bearer ${res.data.data.access_token}`,
            });
            fetch("/api/v1/clients/me", {
              method: "POST",
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {if (data?.success) {setUserData(data?.data); setIsSignOut(prev=>!prev)}})
              .catch((error) => console.error("Error:", error));
          } else {
            // setIsSuccess({ show: !isSuccess.show, value: res.data.success });
            setIsVisible("true");
          }
        })
        .catch((e) => {
            setIsSignOut(false);
        //   setIsSuccess({ show: !isSuccess.show, value: "error" });
          setIsVisible("true");
          console.log(e, 'error')
        });
    // }
  };

  useEffect(() => {
    const loginBtns = document.querySelectorAll(".rt-login");
    if (loginBtns) {
      loginBtns.forEach((element) => {
        element.addEventListener("click", signOutClick);
      });
    }
  }, []);

//   useEffect(() => {
//     setCountClick((prev) => prev + 1);
//     if (countClick > 0) {
//       setIsSignOut((prev) => !prev);
//     }
//   }, [isSuccess]);

  const observer = new MutationObserver(mutationCallback);

  observer.observe(container, { attributes: true });

  useEffect(() => {
    const myRTP = JSON?.parse(localStorage?.getItem("MyRTP"));
    if (myRTP?.SubjectID) {
      getUserHeardList(myRTP.SubjectID);
    }
  }, []);

  const screenSize = useScreenSize();

  const [isSuccessSubdomain, setIsSuccessSubdomain] = useState(false);

  useEffect(() => {
    const currentSubdomain = window.location?.hostname?.split('.')[0];
    if (currentSubdomain === 'realtytexas' || currentSubdomain === 'dev' || currentSubdomain === 'www') setIsSuccessSubdomain(true)
  }, []);

  return (
    <div className="App">
      {isVisible === "true" && <LogInBox container={container} isSuccessSubdomain={isSuccessSubdomain}/>}
      {isSignOut && userData !== null && (
        <SignOutForm
          setIsVisible={setIsVisible}
          setIsSignOut={setIsSignOut}
          subjectID={isUserLogined}
          userData={userData}
          screenWidth={screenSize[0]}
        />
      )}
    </div>
  );
}

export default App;
