import React from "react";
import { colors } from "../../../constants/colors";
import "./baseUnderlineInput.css";

const BaseUnderlineInput = ({
  borderColor = colors.gray,
  borderWeight = "1px",
  height = "50px",
  width = "100%",
  className = "",
  id,
  label,
  type = "text",
  required,
  disabled = false,
  placeholder,
  value,
  labelEndIcon = null,
  onClickLabelEndIcon = null,
  ref,
  max,
  onChange,
  dark = false,
  name,
  maxLength = 40,
  ...props
}) => {
  return (
    <div className={`base-underline-input-container ${className}`}>
      {label && (
        <label htmlFor={id} className={"base-underline-input-label"}>
          {label}
        </label>
      )}
      {labelEndIcon && (
        <div
          className={"base-underline-input-container-labelEndIcon"}
          onClick={onClickLabelEndIcon}
          style={{ cursor: onClickLabelEndIcon ? "pointer" : "initial" }}
        >
          {labelEndIcon}
        </div>
      )}
      <div
        className={`base-underline-input-block ${
          required ? "base-underline-input-block-required" : ""
        }`}
      >
        <input
          style={{
            width: width,
            height: height,
            backgroundColor: dark ? colors.secondBg : "inherit",
            borderBottom: dark
              ? `${borderWeight} solid ${borderColor}`
              : "none",
            color: dark ? colors.bodyText : "inherit",
            paddingRight: labelEndIcon ? "56px" : "24px",
          }}
          // eslint-disable-next-line no-undef
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          required={required}
          type={type}
          value={value}
          name={name}
          maxLength={maxLength}
          max={max}
          {...props}
        />
      </div>
    </div>
  );
};

export default BaseUnderlineInput;
