import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const rootDiv = document?.getElementById("root-react-login-form");

if (rootDiv) {
  const root = ReactDOM.createRoot(rootDiv);
  root.render(
    <GoogleOAuthProvider clientId="729340332754-i08cd1bcemgkt9h5j1bmqg8v9ou7r7ce.apps.googleusercontent.com">
      <App container={rootDiv} />
    </GoogleOAuthProvider>
  );
}
