import { colors } from "./colors";

export function CloseIcon({
  width = 40,
  height = 40,
  strokeWidth = 5,
  color = colors.white,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6519 20L30.0738 12.5781C30.426 12.2265 30.6241 11.7493 30.6246 11.2516C30.625 10.7539 30.4277 10.2764 30.0761 9.92418C29.7245 9.57193 29.2473 9.3738 28.7496 9.37336C28.2519 9.37292 27.7744 9.57021 27.4222 9.92183L20.0003 17.3437L12.5784 9.92183C12.2262 9.56959 11.7485 9.3717 11.2503 9.3717C10.7522 9.3717 10.2744 9.56959 9.9222 9.92183C9.56996 10.2741 9.37207 10.7518 9.37207 11.25C9.37207 11.7481 9.56996 12.2258 9.9222 12.5781L17.3441 20L9.9222 27.4218C9.56996 27.7741 9.37207 28.2518 9.37207 28.75C9.37207 29.2481 9.56996 29.7258 9.9222 30.0781C10.2744 30.4303 10.7522 30.6282 11.2503 30.6282C11.7485 30.6282 12.2262 30.4303 12.5784 30.0781L20.0003 22.6562L27.4222 30.0781C27.7744 30.4303 28.2522 30.6282 28.7503 30.6282C29.2485 30.6282 29.7262 30.4303 30.0784 30.0781C30.4307 29.7258 30.6286 29.2481 30.6286 28.75C30.6286 28.2518 30.4307 27.7741 30.0784 27.4218L22.6519 20Z"
        fill={color}
      ></path>
    </svg>
  );
}

export function RealtyTexasLogoIcon({
  width = 150,
  height = 33,
  color = colors.white,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.6016 1.41689V2.83408H42.6608H43.7201V10.3249V17.8158H43.046H42.3719V19.233V20.6501H45.8386H49.3053V19.233V17.8158H48.1498H46.9942V8.90774V-0.000291824H44.2979H41.6016V1.41689Z"
        fill={color}
      ></path>
      <path
        d="M114.114 4.10935L110.454 7.20692L106.256 5.91121C103.945 5.20261 102.019 4.65598 101.961 4.69647C101.922 4.75721 102.673 6.49833 103.636 8.58362L105.408 12.3695L104.156 13.726C102.558 15.4671 100.902 17.6334 99.4379 19.9414C97.9742 22.2493 96.8378 24.3144 96.9534 24.4359C96.9919 24.4966 97.666 23.8285 98.4556 22.9579C103.444 17.4309 110.416 12.5112 115.943 10.6082C116.848 10.3045 117.638 10.0008 117.715 9.94007C117.869 9.79835 118.023 1.01179 117.869 1.01179C117.811 1.03203 116.136 2.40873 114.114 4.10935Z"
        fill={color}
      ></path>
      <path
        d="M52.0581 3.07644L50.6714 3.6838L50.5559 4.95927C50.4788 5.66786 50.4788 6.33597 50.5559 6.45744C50.6329 6.6194 50.267 6.68014 49.4196 6.68014H48.1484V7.9961V9.31206H49.304H50.4596V13.3207C50.4596 17.7949 50.6522 18.9692 51.5574 19.8802C52.3277 20.6496 53.0596 20.8925 54.7159 20.8925C56.1218 20.9127 58.3559 20.3864 58.3559 20.0422C58.3559 19.9612 58.1826 19.4551 57.9515 18.9287C57.547 17.9771 57.547 17.9771 56.8537 18.1391C55.7752 18.4023 54.3692 18.3618 54.0803 18.0581C53.8877 17.8557 53.8107 16.6814 53.7722 13.5636L53.7144 9.31206H55.5441H57.393V7.9961V6.68014H55.5441H53.6759L53.8107 4.55436C53.9263 2.85373 53.907 2.42858 53.6951 2.44883C53.5603 2.44883 52.8285 2.73226 52.0581 3.07644Z"
        fill={color}
      ></path>
      <path
        d="M83.4487 3.07729L82.0428 3.68465V5.18282V6.68099H80.7909H79.5391V7.99695V9.31291H80.7524H81.9465L81.8887 13.0786C81.8309 17.4719 82.0043 18.7473 82.8324 19.7394C83.5835 20.6302 84.4887 20.9338 86.2799 20.9338C87.6665 20.9136 89.7466 20.4075 89.7466 20.043C89.7466 19.9621 89.5732 19.4559 89.3421 18.9295C89.0147 18.14 88.8799 17.9982 88.5332 18.0995C87.5317 18.3424 86.4532 18.4639 86.0295 18.3424C85.2206 18.14 85.105 17.4516 85.1436 13.2203L85.1628 9.31291H86.9732H88.7836V7.99695V6.68099H87.0117H85.2206L85.2784 4.55521C85.3361 2.89508 85.2784 2.42943 85.0858 2.44967C84.951 2.44967 84.1998 2.73311 83.4487 3.07729Z"
        fill={color}
      ></path>
      <path
        d="M95.5243 6.07324C93.425 6.47815 91.7302 7.42969 90.4976 8.86712C89.2843 10.2641 88.9183 11.5395 89.0146 13.726C89.2072 17.9371 91.8265 20.893 95.3895 20.9335C97.354 20.9537 98.0858 20.7512 98.4518 20.0831C98.6251 19.7795 99.3377 18.7267 100.031 17.7347C101.283 15.9935 101.553 15.285 100.667 16.1353C99.7036 17.0868 97.7584 17.8359 96.2947 17.8359C95.0814 17.8561 94.2725 17.5727 93.6369 16.9451C93.1362 16.4592 92.5584 15.4064 92.6932 15.2445C92.751 15.204 94.9851 14.5156 97.6621 13.7463C100.358 12.9567 102.612 12.2481 102.708 12.1671C102.939 11.9242 102.13 9.69719 101.379 8.56344C100.705 7.53092 99.7229 6.6806 98.7984 6.31618C98.028 6.0125 96.4488 5.89103 95.5243 6.07324ZM97.7392 9.27203C97.951 9.37326 98.2784 9.75793 98.471 10.1021L98.8177 10.7095L97.7969 11.0334C97.2384 11.1954 95.7169 11.6408 94.4073 12.0254L92.0576 12.6935L92.1732 11.9647C92.3658 10.8512 92.8665 10.0616 93.7332 9.55547C95.1006 8.76589 96.6606 8.64442 97.7392 9.27203Z"
        fill={color}
      ></path>
      <path
        d="M7.41488 6.31601C7.10672 6.43748 6.60598 6.72092 6.31709 6.92337L5.77782 7.30804V6.8019V6.27552H2.88891H0V7.79393V9.31235H1.15556H2.31113V13.5639V17.8155H1.15556H0V19.3339V20.8523H4.23707H8.47414V19.3339V17.8155H7.12598H5.77782V14.6167C5.77782 12.005 5.8356 11.3167 6.12449 10.7295C6.70228 9.49455 8.47414 8.58351 8.47414 9.5148C8.47414 9.89947 8.60896 9.91971 10.2075 9.91971H11.9408V7.99639V6.07306L9.97638 6.09331C8.87859 6.09331 7.74229 6.19453 7.41488 6.31601Z"
        fill={color}
      ></path>
      <path
        d="M17.6605 6.35708C15.2916 7.08592 13.3079 8.70556 12.5182 10.6086C11.0545 14.0504 12.7301 18.8283 15.9657 20.4277C16.8709 20.8731 17.3138 20.9541 18.7775 20.9541C21.4546 20.9541 23.6694 19.9823 26.0961 17.7351L26.8665 17.0265L26.3272 16.4393C26.0383 16.1154 25.5761 15.7308 25.3257 15.589C24.9213 15.3663 24.7865 15.4068 24.1124 15.9737C23.6887 16.3179 22.745 16.905 22.0131 17.2492C20.9346 17.7958 20.4724 17.897 19.3553 17.897C17.7375 17.9173 16.9864 17.5529 16.1775 16.3584C15.8501 15.913 15.5997 15.4676 15.5997 15.4068C15.5997 15.3461 17.4872 14.7387 19.7983 14.0909C26.4621 12.1878 25.865 12.4307 25.7302 11.6817C25.6724 11.3375 25.4028 10.5681 25.1332 9.96078C24.0161 7.40985 22.4561 6.23561 20.0294 6.15462C19.2013 6.11413 18.1998 6.19511 17.6605 6.35708ZM20.4724 9.19145C21.0502 9.4344 21.7628 10.2645 21.7628 10.6896C21.7628 10.7908 20.3953 11.2565 18.739 11.7221C17.0827 12.208 15.5997 12.6332 15.4649 12.6939C15.1375 12.8356 15.1375 11.9246 15.4842 11.0743C15.8309 10.224 16.3123 9.77857 17.4486 9.31293C18.5657 8.84728 19.625 8.80679 20.4724 9.19145Z"
        fill={color}
      ></path>
      <path
        d="M31.2001 6.57935C29.8134 6.94377 28.3112 7.61187 28.3112 7.89531C28.3112 8.19899 29.043 9.65667 29.2549 9.81863C29.3705 9.87937 29.9868 9.73765 30.6223 9.51495C31.9127 9.0493 34.2238 8.92783 35.052 9.272C35.8609 9.59593 36.2268 10.2033 36.3424 11.3978L36.4579 12.5113L34.8209 12.4101C29.9097 12.0861 26.8282 14.2929 27.4253 17.7144C27.6179 18.8076 28.8697 20.1438 30.1986 20.6702C31.7394 21.2776 33.9542 21.1561 35.3987 20.3868C36.3616 19.8604 36.4001 19.8402 36.4001 20.2451C36.4001 20.6297 36.5157 20.65 38.8076 20.65H41.215V19.2328V17.8156H40.4061H39.578V13.9082C39.578 11.2358 39.5009 9.79839 39.3276 9.272C38.9616 8.21924 37.6135 6.98426 36.3809 6.59959C35.1483 6.21493 32.6638 6.19468 31.2001 6.57935ZM36.3616 16.034L36.3039 17.3095L35.4372 17.8156C34.7438 18.2205 34.3009 18.3218 33.2031 18.3218C31.6431 18.3218 30.8727 17.9978 30.6801 17.2285C30.2371 15.4469 31.2001 14.799 34.3394 14.7788H36.4194L36.3616 16.034Z"
        fill={color}
      ></path>
      <path
        d="M123.162 6.4174C122.95 6.45789 122.373 6.6401 121.872 6.80206L120.986 7.10574L120.909 8.4622C120.87 9.21128 120.851 9.85914 120.89 9.89963C120.909 9.94012 121.275 9.81865 121.699 9.65668C122.989 9.11005 124.838 8.86711 125.916 9.1303C127.187 9.41374 127.688 9.98061 127.823 11.3573L127.919 12.4506L125.454 12.4708C122.642 12.4708 121.313 12.8757 120.042 14.0297C118.058 15.8721 118.501 18.9291 120.967 20.3463C121.833 20.8322 122.276 20.9537 123.682 21.0144C125.242 21.0954 125.454 21.0549 126.61 20.488L127.881 19.8604V20.3666V20.8525H130.288H132.696V19.334V17.8156H131.887H131.059L131.116 14.4549C131.193 10.0616 131.02 9.17079 129.845 7.89532C128.709 6.66034 127.534 6.27568 125.204 6.29592C124.299 6.31617 123.374 6.35666 123.162 6.4174ZM127.842 16.034C127.785 17.3095 127.785 17.3095 126.937 17.7954C125.801 18.4635 123.817 18.6052 122.893 18.0788C121.621 17.3702 121.718 15.8518 123.066 15.1432C123.586 14.8598 124.222 14.7788 125.82 14.7788H127.9L127.842 16.034Z"
        fill={color}
      ></path>
      <path
        d="M137.242 6.68054C135.586 7.36889 134.431 8.86705 134.431 10.3652C134.431 11.4382 135.162 12.734 136.145 13.4021C136.626 13.726 138.109 14.3536 139.438 14.7787C142.365 15.7505 142.751 16.0137 142.674 16.8438C142.577 17.8156 141.788 18.3217 140.343 18.3217C138.475 18.3217 137.512 17.7346 137.512 16.6211C137.512 16.2162 137.396 16.1959 135.586 16.1959H133.66V17.7143V19.2328H134.681C135.451 19.2328 135.798 19.334 136.048 19.6174C136.722 20.407 138.244 20.8929 140.016 20.9131C143.733 20.9334 145.986 19.3137 145.986 16.6008C145.986 14.4346 144.831 13.3413 141.383 12.2481C137.859 11.1143 137.531 10.8916 137.782 9.83884C137.974 9.04926 139.515 8.58362 140.921 8.90755C141.942 9.15049 142.712 9.7781 142.712 10.3652C142.712 10.6892 142.885 10.7296 144.349 10.7296H145.986V9.31246V7.89527H145.389C145.062 7.89527 144.522 7.67257 144.195 7.40938C143.174 6.6198 141.865 6.27563 139.939 6.27563C138.687 6.29587 137.916 6.3971 137.242 6.68054Z"
        fill={color}
      ></path>
      <path
        d="M58.1641 7.97628V9.29224L58.973 9.35298L59.7819 9.41371L61.8426 14.6775C62.9789 17.5727 64.0574 20.2046 64.2115 20.5083C64.5004 21.0346 64.4812 21.1561 63.9034 22.4923C63.056 24.4764 62.1893 24.9218 60.687 24.0917L59.9359 23.7071L59.1463 24.6181C58.7226 25.1242 58.3567 25.5696 58.3567 25.6304C58.3567 25.6911 59.0115 26.0555 59.8011 26.4604C61.0915 27.1083 61.3804 27.169 62.4974 27.0881C63.9997 26.9868 64.8471 26.3795 65.8101 24.6991C66.4071 23.6463 66.8501 22.5328 70.1242 13.6045L71.7034 9.31248H72.5508H73.3983L73.3405 8.03702L73.2827 6.78179L70.259 6.72106L67.216 6.66032V7.99652V9.31248H67.9093C68.4871 9.31248 68.5834 9.37322 68.4679 9.65666C68.3908 9.85911 67.7745 11.6407 67.0812 13.6045L65.8101 17.2082L64.3463 13.3009L62.8634 9.41371L63.4989 9.35298L64.1345 9.29224V7.97628V6.68056H61.1493H58.1641V7.97628Z"
        fill={color}
      ></path>
      <path
        d="M147.315 8.28035C146.737 8.76624 146.699 9.96073 147.257 10.5478C148.143 11.4589 149.877 10.811 149.992 9.53557C150.127 8.19937 148.374 7.3693 147.315 8.28035ZM148.894 8.50305C149.145 8.66501 149.26 8.92821 149.26 9.45459C149.26 9.8595 149.203 10.1227 149.126 10.062C149.068 9.98097 149.01 9.65705 149.029 9.33312C149.029 8.84722 148.933 8.68526 148.528 8.5233C148.24 8.42207 148.124 8.32084 148.259 8.32084C148.394 8.30059 148.683 8.40182 148.894 8.50305ZM147.758 9.31287C147.662 10.2037 147.623 10.3049 147.488 9.75827C147.411 9.43434 147.623 8.50305 147.758 8.50305C147.797 8.50305 147.797 8.86747 147.758 9.31287ZM148.683 10.3656C148.683 10.4669 148.567 10.5276 148.413 10.5276C148.24 10.5276 148.182 10.3859 148.24 10.1024C148.317 9.77852 148.394 9.75827 148.509 9.96073C148.606 10.1024 148.683 10.2847 148.683 10.3656Z"
        fill={color}
      ></path>
      <path
        d="M120.754 10.8718C117.172 11.2565 113.975 12.208 111.182 13.7669C109.333 14.7792 107.484 16.1559 107.484 16.5001C107.465 16.8037 112.222 25.9952 112.415 26.0559C112.511 26.0964 112.55 25.7928 112.511 25.4081C112.319 23.667 113.224 19.7393 114.264 17.8565C115.805 15.0221 118.925 12.3092 121.659 11.3982C123.316 10.8516 122.93 10.6289 120.754 10.8718Z"
        fill={color}
      ></path>
      <path
        d="M75.3047 26.5212V27.331H76.2677H77.2306V30.1654V32.9998H78.001H78.7714V30.1654V27.331H79.7344H80.6973V26.5212V25.7114H78.001H75.3047V26.5212Z"
        fill={color}
      ></path>
      <path
        d="M81.082 29.3558V33H81.9487H82.8154V31.4816V29.9632H84.1635H85.5117V31.4816V33H86.2821H87.0525V29.3558V25.7116H86.2821H85.5117V27.0276V28.3435H84.1635H82.8154V27.0276V25.7116H81.9487H81.082V29.3558Z"
        fill={color}
      ></path>
      <path
        d="M87.8242 29.3558V33H90.3279H92.8317V32.1902V31.3804H91.1946H89.5576V30.773V30.1656H91.002H92.4465V29.3558V28.546H91.002C89.5961 28.546 89.5576 28.5257 89.5576 28.0398C89.5576 27.5337 89.5768 27.5337 91.0983 27.5337H92.6391V26.6227V25.7116H90.2316H87.8242V29.3558Z"
        fill={color}
      ></path>
      <path
        d="M96.4922 29.3556V32.9998H97.2626H98.0329V31.5826C98.0329 29.72 98.3604 29.72 99.3233 31.5826L100.055 32.9998H100.98C101.48 32.9998 101.885 32.939 101.885 32.8378C101.885 32.7568 101.557 32.1292 101.134 31.4206L100.402 30.1451L101.076 29.4365C101.692 28.7887 101.731 28.6672 101.634 27.7967C101.48 26.258 100.479 25.7114 97.8981 25.7114H96.4922V29.3556ZM99.7663 27.6347C100.19 28.1813 99.8433 28.5457 98.8996 28.5457C98.0715 28.5457 98.0329 28.5255 98.0329 27.9384C98.0329 27.3715 98.0715 27.331 98.7841 27.331C99.2078 27.331 99.6315 27.4525 99.7663 27.6347Z"
        fill={color}
      ></path>
      <path
        d="M102.461 29.3558V33H104.888H107.295L107.237 32.1497L107.179 31.3196L105.697 31.3601L104.194 31.4006V30.7932V30.1656H105.639H107.083V29.3558V28.546H105.639C104.233 28.546 104.194 28.5257 104.194 28.0398C104.194 27.5337 104.214 27.5337 105.735 27.5337H107.276V26.6227V25.7116H104.868H102.461V29.3558Z"
        fill={color}
      ></path>
      <path
        d="M110.299 26.2582C109.759 27.3313 107.66 32.5546 107.66 32.7773C107.66 32.8988 108.007 33 108.508 33C109.297 33 109.374 32.9595 109.625 32.2712C109.894 31.5423 109.914 31.5423 111.088 31.5018L112.282 31.4816L112.571 32.2307C112.841 32.9798 112.879 33 113.842 33C114.382 33 114.786 32.9393 114.748 32.838C114.709 32.7571 114.517 32.3319 114.343 31.8865C111.897 25.9748 111.82 25.8128 111.185 25.7521C110.684 25.6914 110.53 25.7926 110.299 26.2582ZM111.204 29.9227C110.549 30.0239 110.53 29.9632 110.78 29.0521L111.011 28.2828L111.358 29.0724L111.685 29.8619L111.204 29.9227Z"
        fill={color}
      ></path>
      <path
        d="M115.172 29.3558V33H117.387H119.602V32.1902V31.3804H118.253H116.905V28.546V25.7116H116.039H115.172V29.3558Z"
        fill={color}
      ></path>
      <path
        d="M122.105 29.3353V32.9998H123.839C125.765 32.9998 126.381 32.7771 127.46 31.7243C129.058 30.1249 128.577 27.1893 126.554 26.258C125.957 25.9948 125.091 25.8126 123.897 25.7519L122.105 25.6506V29.3353ZM126.073 27.9991C126.477 28.3635 126.593 28.647 126.593 29.3151C126.593 30.55 126.131 31.0157 124.667 31.2789L123.839 31.4206V29.477V27.5335H124.686C125.303 27.5335 125.688 27.6549 126.073 27.9991Z"
        fill={color}
      ></path>
      <path
        d="M129.039 29.3558V33H131.543H134.047V32.1902V31.3804H132.409H130.772V30.773V30.1656H132.217H133.661V29.3558V28.546H132.217C130.811 28.546 130.772 28.5257 130.772 28.0398C130.772 27.5337 130.792 27.5337 132.313 27.5337H133.854V26.6227V25.7116H131.446H129.039V29.3558Z"
        fill={color}
      ></path>
      <path
        d="M137.145 26.076C136.76 26.8454 134.43 32.5951 134.43 32.7975C134.43 32.8988 134.815 33 135.277 33C136.067 33 136.144 32.9595 136.394 32.2712C136.664 31.5423 136.683 31.5423 137.858 31.5018L139.052 31.4816L139.341 32.2307C139.61 32.9798 139.649 33 140.612 33C141.151 33 141.556 32.9393 141.517 32.838C141.479 32.7571 141.286 32.3319 141.113 31.8865C138.667 25.9748 138.59 25.8128 137.954 25.7521C137.511 25.7116 137.299 25.7926 137.145 26.076ZM138.243 29.2951C138.455 29.9429 138.436 29.9632 137.858 29.9632C137.415 29.9632 137.299 29.9024 137.396 29.6392C137.453 29.457 137.569 29.0926 137.627 28.8092C137.781 28.2423 137.954 28.384 138.243 29.2951Z"
        fill={color}
      ></path>
      <path
        d="M142.133 29.3556V32.9998H144.271H146.389L146.331 32.1494L146.274 31.2991L144.983 31.3599L143.674 31.4004V28.566V25.7114H142.903H142.133V29.3556Z"
        fill={color}
      ></path>
    </svg>
  );
}

export function ArrowUp({ color = colors.mainBg, style }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.6213 16.229C18.7087 16.3156 18.8116 16.3833 18.924 16.4282C19.0365 16.4731 19.1563 16.4944 19.2765 16.4908C19.3968 16.4872 19.5153 16.4589 19.6251 16.4074C19.7349 16.3559 19.834 16.2822 19.9167 16.1907C19.9993 16.0991 20.0639 15.9913 20.1068 15.8736C20.1497 15.7558 20.17 15.6304 20.1666 15.5044C20.1632 15.3784 20.1362 15.2544 20.087 15.1394C20.0378 15.0243 19.9675 14.9206 19.88 14.834L11.6349 6.67892C11.4648 6.51051 11.2396 6.41667 11.0055 6.41667C10.7714 6.41667 10.5462 6.51051 10.3761 6.67892L2.13001 14.834C2.04064 14.92 1.96846 15.0237 1.91769 15.1392C1.86691 15.2546 1.83854 15.3795 1.83423 15.5065C1.82993 15.6335 1.84976 15.7602 1.89258 15.8791C1.93541 15.998 2.00037 16.1069 2.0837 16.1993C2.16702 16.2917 2.26705 16.3658 2.37798 16.4174C2.4889 16.469 2.6085 16.497 2.72984 16.4998C2.85118 16.5025 2.97183 16.48 3.08479 16.4336C3.19775 16.3871 3.30077 16.3176 3.38786 16.229L11.0055 8.69563L18.6213 16.229Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowDown({ color = colors.mainBg, style }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.6214 5.77097C18.7088 5.68441 18.8117 5.61675 18.9242 5.57183C19.0366 5.52691 19.1564 5.50563 19.2767 5.50919C19.397 5.51276 19.5154 5.5411 19.6252 5.5926C19.7351 5.6441 19.8341 5.71775 19.9168 5.80935C19.9994 5.90095 20.064 6.0087 20.1069 6.12644C20.1498 6.24419 20.1702 6.36964 20.1667 6.49561C20.1633 6.62158 20.1363 6.74562 20.0871 6.86065C20.0379 6.97567 19.9676 7.07942 19.8801 7.16598L11.635 15.3211C11.4649 15.4895 11.2397 15.5833 11.0056 15.5833C10.7715 15.5833 10.5463 15.4895 10.3762 15.3211L2.13014 7.16598C2.04076 7.07999 1.96858 6.97626 1.91781 6.86082C1.86703 6.74537 1.83867 6.62051 1.83436 6.49349C1.83005 6.36647 1.84988 6.23981 1.89271 6.12089C1.93553 6.00196 2.00049 5.89313 2.08382 5.80072C2.16714 5.7083 2.26717 5.63415 2.3781 5.58257C2.48902 5.53098 2.60862 5.50299 2.72996 5.50023C2.8513 5.49746 2.97195 5.51997 3.08492 5.56645C3.19788 5.61293 3.30089 5.68245 3.38798 5.77097L11.0056 13.3044L18.6214 5.77097Z"
        fill={color}
      />
    </svg>
  );
}

export function ErrorIcon({ width = 18, height = 18, color = colors.red }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.97093 2.51482C8.99075 1.94927 10.288 2.32085 10.8579 3.33157L16.3095 12.7929C16.4295 13.0753 16.482 13.305 16.497 13.5435C16.527 14.1009 16.332 14.6427 15.9496 15.0596C15.5671 15.475 15.0497 15.7203 14.4873 15.75H3.50918C3.27672 15.7359 3.04426 15.6831 2.82679 15.6014C1.73948 15.1629 1.21456 13.9292 1.65699 12.8598L7.14606 3.32488C7.33353 2.98971 7.61848 2.70061 7.97093 2.51482ZM8.99825 11.4545C8.63831 11.4545 8.33836 11.7517 8.33836 12.1092C8.33836 12.4652 8.63831 12.7632 8.99825 12.7632C9.35819 12.7632 9.65064 12.4652 9.65064 12.101C9.65064 11.745 9.35819 11.4545 8.99825 11.4545ZM8.99825 6.8178C8.63831 6.8178 8.33836 7.10689 8.33836 7.46436V9.5668C8.33836 9.92352 8.63831 10.2215 8.99825 10.2215C9.35819 10.2215 9.65064 9.92352 9.65064 9.5668V7.46436C9.65064 7.10689 9.35819 6.8178 8.99825 6.8178Z"
        fill={color}
      ></path>
    </svg>
  );
}

export function OpenEyeIcon({
  width = 18,
  height = 18,
  color = colors.bodyText,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"
      />
    </svg>
  );
}

export function CloseEyeIcon({
  width = 18,
  height = 18,
  color = colors.bodyText,
}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"
      />
    </svg>
  );
}

export function EditIcon({ width = 24, height = 24, color = colors.bodyText }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12.5,10.2071068 L8,14.7071068 L8,16 L9.29289322,16 L13.7928932,11.5 L12.5,10.2071068 Z M13.2071068,9.5 L14.5,10.7928932 L15.7928932,9.5 L14.5,8.20710678 L13.2071068,9.5 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M14.8535534,7.14644661 L16.8535534,9.14644661 C17.0488155,9.34170876 17.0488155,9.65829124 16.8535534,9.85355339 L9.85355339,16.8535534 C9.7597852,16.9473216 9.63260824,17 9.5,17 L7.5,17 C7.22385763,17 7,16.7761424 7,16.5 L7,14.5 C7,14.3673918 7.05267842,14.2402148 7.14644661,14.1464466 L14.1464466,7.14644661 C14.3417088,6.95118446 14.6582912,6.95118446 14.8535534,7.14644661 Z"
      />
    </svg>
  );
}

export function SendIcon({ width = 24, height = 24, color = colors.bodyText }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 511.763 511.763"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M511.716,9.802c-0.107-0.853-0.213-1.707-0.533-2.56c-0.107-0.32-0.213-0.747-0.32-1.067    c-0.533-1.173-1.28-2.24-2.133-3.2c-0.96-0.853-2.027-1.6-3.2-2.133c-0.32-0.107-0.747-0.32-1.067-0.32    c-0.853-0.213-1.707-0.427-2.56-0.427c-0.427,0-0.747,0-1.173,0c-0.96,0-2.027,0.213-2.987,0.533    c-0.213,0.107-0.427,0.107-0.64,0.213h-0.107L6.436,213.962c-5.44,2.347-7.893,8.64-5.547,14.08c0.96,2.24,2.667,4.053,4.8,5.12    l178.347,94.4l94.507,178.347c1.813,3.52,5.44,5.653,9.387,5.76h0.427c4.053-0.107,7.68-2.667,9.387-6.4L510.969,14.815v-0.107    c0.107-0.213,0.107-0.427,0.213-0.64c0.32-0.96,0.533-1.92,0.533-2.987C511.716,10.655,511.822,10.228,511.716,9.802z     M35.342,224.522l418.88-182.08l-264.107,264L35.342,224.522z M287.182,476.362l-81.92-154.773l264-264.107L287.182,476.362z"
          />
        </g>
      </g>
    </svg>
  );
}
