export const colors = {
  mainBg: "#141313",
  blue: "#2078BD",
  bodyText: "#F2F2F2",
  red: "#FF2822",
  yellow: "#FFC805",
  secondYellow: "#FFBF44",
  gray: "#908F8F",
  lightGray: "#f9f9f9",
  white: "#FFFFFF",
  secondBg: "#2B2A2A",
  lightBr: "#908f8f45",
};
