import axios from "axios";

export const getUserHeardList = (hashId) => {
  const setItemToLocStorage = (listings, hashId) => {
    const data = JSON.parse(localStorage.getItem("listingInteractions"));
    const filteredArray = data?.listings.filter(
      (element) => !listings.some((filterItem) => filterItem.id === element.id)
    );
    if (data !== null) {
      localStorage.setItem(
        "listingInteractions",
        JSON.stringify({
          userId: hashId,
          listings: [...filteredArray, ...listings],
        })
      );
    } else {
      localStorage.setItem(
        "listingInteractions",
        JSON.stringify({ userId: hashId, listings: [...listings] })
      );
    }
  };

  axios
    .get(
      `https://web.realtytexas.io/api/v1/clients/identity/${hashId}/listings/interactions`
    )
    .then(function (response) {
      const heardData = [
        ...response?.data?.data.map((item) => {
          return { id: item.id, value: item.value };
        }),
      ];
      setItemToLocStorage(heardData, hashId);
    })
    .then((res) => {
      const RTLoginEvent = new CustomEvent("RTLoginEvent", {
        detail: {
          SubjectID: hashId,
        },
      });
      window.document.dispatchEvent(RTLoginEvent);
    })
    .catch(function (error) {
      console.log(error);
    });
};
