import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { colors } from "../../../constants/colors";
import { ArrowDown, ArrowUp } from "../../../constants/icons";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import "./baseInputList.css";

const BaseInputList = ({
  readonly = false,
  name = "sorting",
  className,
  classNameList,
  onClick,
  list,
  placeholder = "Sort By Default",
  colorEndIcon = colors.mainBg,
  height,
  listTop = "54px",
  padding,
  field = null,
  field2 = null,
  setInputValue,
  inputValue,
  setInValue2,
  dark = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(!isVisible);
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItem = (item) => {
    setInputValue(item);
    setIsVisible(false);
    if (setInValue2) setInValue2(item);
  };

  return (
    <div>
      <div
        className="filters-page-base-input-list-wrapper"
        onClick={() => {
          inputHandler();
          if (onClick) onClick();
        }}
      >
        <div className="filters-page-base-input-list-end-icon-wrapper"></div>
        <ClickOutside onClick={clickOutsideHandler}>
          <div className="filters-page-base-input-list-end-icon-wrapper">
            <ArrowUp
              color={colorEndIcon}
              style={{ display: !isVisible ? "none" : "block" }}
            />
            <ArrowDown
              color={colorEndIcon}
              style={{ display: isVisible ? "none" : "block" }}
            />
          </div>
          <input
            name={name}
            type="text"
            placeholder={placeholder}
            className={className}
            readOnly={readonly}
            value={inputValue[field] ? inputValue[field] : inputValue}
            style={{
              width: "100%",
              height: height,
              backgroundColor: dark ? colors.secondBg : "inherit",
              border: dark ? `1px solid ${colors.gray}` : "none",
              color: dark
                ? inputValue?.value !== null || inputValue?.value === undefined
                  ? colors.bodyText
                  : colors.gray
                : "inherit",
              borderBottomRightRadius: isVisible ? 0 : 10,
              borderBottomLeftRadius: isVisible ? 0 : 10,
              padding: padding ? padding : "19px 24px",
            }}
          />
        </ClickOutside>
        <ul style={{ display: isVisible ? "block" : "none", top: listTop }}>
          {list.map((item, i) => {
            if (field) {
              return (
                <li
                  key={i}
                  onClick={() => onIconItem(item)}
                  className={classNameList}
                  style={{
                    backgroundColor: dark ? colors.secondBg : "inherit",
                    color: dark ? colors.bodyText : "inherit",
                    position: "relative",
                  }}
                >
                  {!field2 && (
                    <p
                      style={{
                        backgroundColor: dark ? colors.secondBg : "inherit",
                        color: dark ? colors.bodyText : "inherit",
                      }}
                    >
                      {item[field]}
                    </p>
                  )}
                  {field2 && (
                    <div className="base-input-list-field2-wrapper">
                      <div>{item[field]}</div>
                      <div
                        className={
                          item[field2] === true
                            ? "base-input-list-field2-active"
                            : "base-input-list-field2-inactive"
                        }
                      >
                        {item[field2] === true ? "(Active)" : "(Inactive)"}
                      </div>
                    </div>
                  )}
                </li>
              );
            } else {
              return (
                <li
                  key={i}
                  onClick={() => onIconItem(item)}
                  className={classNameList}
                  style={{
                    backgroundColor: dark ? colors.secondBg : "inherit",
                    color: dark ? colors.bodyText : "inherit",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: dark ? colors.secondBg : "inherit",
                      color: dark ? colors.bodyText : "inherit",
                    }}
                  >
                    {item}
                  </p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default BaseInputList;
