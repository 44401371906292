export const isUserLogin = () => {
  const myRTP = JSON.parse(localStorage.getItem("MyRTP"));
  if (myRTP && myRTP.hasOwnProperty("SubjectID")) {
    return myRTP.SubjectID;
  } else return false;
};

export const getApiUrl  =   (path)  =>{
    const apiUrl = process.env.REACT_APP_API_URL || window.location.origin;
    if(path && apiUrl){
        return `${apiUrl}${(!path.startsWith("/") && !apiUrl.endsWith("/")?"/":"")}${path}`
    }
    return apiUrl;
}

