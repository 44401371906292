import React, { useEffect } from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { ClickOutside } from "../../ui";
import "./alertMessage.css";

const AlertMessage = ({
  header,
  content,
  onClick,
  logInSuccess=false,
  headerColor = false,
  contentColor = false,
}) => {
    const logInClose = () => {
        setTimeout(
          function() {
            onClick()
          }, 5000);
      }
    useEffect(()=>{
        if (logInSuccess) logInClose()
    },[])

    const handleClickOutside = () => {
        if (logInSuccess) onClick()
    }

  return (
    <ClickOutside onClick={handleClickOutside}>
    <div className="alert-message-wrapper">
      <div className="alert-message-close" onClick={onClick}>
        <CloseIcon width={24} height={24} />
      </div>
      <div className="alert-message-content">
        <div className="alert-message-content-header">
          <span style={{ color: headerColor ? headerColor : colors.bodyText }}>
            {header}
          </span>
        </div>
        <div className="alert-message-content-text">
          <span
            style={{ color: contentColor ? contentColor : colors.bodyText }}
          >
            {content}
          </span>
        </div>
      </div>
    </div>
    </ClickOutside>
  );
};

export default AlertMessage;
