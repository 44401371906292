import React, { useEffect, useState } from "react";
import { LogInForm } from "../../components";
import ConfirmMessage from "../../components/ConfirmMessage/ConfirmMessage";
import ConfirmMessageLogInForm from "../../components/ConfirmMessageLogInForm/ConfirmMessageLogInForm";
import ForgotPassForm from "../../components/ForgotPassForm/ForgotPassForm";
import SignInForm from "../../components/SignUpForm/SignUpForm";
import "./logInBox.css";
import "./responsive.css";

const LogInBox = ({ container, isSuccessSubdomain }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPass, setIsResetPass] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isConfirmMessage, setIsConfirmMessage] = useState(false);
  const [isConfirmMessageLogInForm, setIsConfirmMessageLogInForm] =
    useState(false);
  const [hashId, setHashId] = useState(null);
  const [email, setEmail] = useState("");
  const [unConfirmMessage, setUnConfirmMessage] = useState(null);

  return (
    <div className="login-box-wrapper">
      {isLoginForm && (
        <LogInForm
          container={container}
          setIsSignUp={setIsSignUp}
          setIsResetPass={setIsResetPass}
          setIsLoginForm={setIsLoginForm}
          setEmail={setEmail}
          setIsConfirmMessageLogInForm={setIsConfirmMessageLogInForm}
          setUnConfirmMessage={setUnConfirmMessage}
          setHashId={setHashId}
          isSuccessSubdomain={isSuccessSubdomain}
        />
      )}
      {isResetPass && (
        <ForgotPassForm
          container={container}
          setIsResetPass={setIsResetPass}
          setIsLoginForm={setIsLoginForm}
        />
      )}
      {isSignUp && (
        <SignInForm
          container={container}
          setIsSignUp={setIsSignUp}
          setIsLoginForm={setIsLoginForm}
          setHashId={setHashId}
          hashId={hashId}
          setIsConfirmMessage={setIsConfirmMessage}
          setEmail={setEmail}
          email={email}
          isConfirmMessage={isConfirmMessage}
        />
      )}
      {isConfirmMessage && (
        <ConfirmMessage
          hashId={hashId ? hashId : "testhashId"}
          baseEmail={email ? email : "testemail"}
          setIsConfirmMessage={setIsConfirmMessage}
          container={container}
        />
      )}
      {isConfirmMessageLogInForm && (
        <ConfirmMessageLogInForm
          hashId={hashId}
          setIsConfirmMessageLogInForm={setIsConfirmMessageLogInForm}
          unConfirmMessage={unConfirmMessage}
          container={container}
        />
      )}
    </div>
  );
};

export default LogInBox;
