import React, { Children } from "react";
import { colors } from "../../../constants/colors";
import "./baseButton.css";

const BaseButton = ({
  height = "50px",
  width = "100%",
  className,
  children,
  onClick,
  startIcon,
  endIcon,
  attributes,
  bg = colors.blue,
  textColor = colors.bodyText,
  size = "16px",
  bold = false,
  dark = false,
  type="button",
  ...props
}) => {
  return (
    <div className={`base-button-container ${className}`}>
      {startIcon && <div className="start-icon">{startIcon}</div>}
      <button
      type={type}
        style={{
          width: width,
          height: height,
          backgroundColor: bg,
          color: textColor,
          fontSize: size,
          fontWeight: bold ? 600 : 400,
        }}
        {...attributes}
        onClick={onClick}
      >
        {children}
      </button>
      {endIcon && <div className="end-icon">{endIcon}</div>}
    </div>
  );
};

export default BaseButton;
