import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import {
  CloseIcon,
  EditIcon,
  ErrorIcon,
  RealtyTexasLogoIcon,
  SendIcon,
  container,
} from "../../constants/icons";
import { UseValidateEmail } from "../../hooks";
import { BaseInput, BaseUnderlineInput } from "../../ui";
import { BaseButton } from "../../ui/Buttons";
import axios from "axios";
import "./confirmMessageLogInForm.css";
import "./responsive.css";
import AlertMessage from "../AlertMessage/AlertMessage";
import {getApiUrl} from "../../constants/methods";

const ConfirmMessageLogInForm = ({
  header = "Verify it's you",
  headerColor = false,
  contentColor = false,
  hashId = null,
  setIsConfirmMessageLogInForm,
  container,
  unConfirmMessage,
}) => {
  const [code, setCode] = useState("");
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [codeErrorText, setCodeErrorText] = useState(false);

  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isErrorAlertMessage, setIsErrorAlertMessage] =
    useState("Base error text");
  const [isSuccessAlertMessage, setIsSuccessAlertMessage] =
    useState("Base error text");

  const [isSuccessTryAgainAlert, setIsSuccessTryAgainAlert] = useState(false);
  const [isSuccessTryAgainAlertMessage, setIsSuccessTryAgainAlertMessage] =
    useState("Base error text");

  const [isBaseAlert, setIsBaseAlert] = useState(true);

  const checkErrors = () => {
    let result1 = false;
    //valid code
    if (code === "") {
      setCodeError(true);
      setCodeErrorText("Required!");
      result1 = true;
    } else if (/^\d+$/.test(code) !== true) {
      setCodeError((prev) => true);
      setCodeErrorText("Should be digits only!");
      result1 = true;
    } else if (code.length < 4) {
      setCodeError((prev) => true);
      setCodeErrorText("Should be 4 digits!");
      result1 = true;
    } else {
      setCodeError((prev) => false);
    }
    if (!result1) {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (isFirstSubmit) checkErrors();
  }, [code, isFirstSubmit]);

  const onTryItHandle = () => {
    const dataObj = { hash_id: hashId };
    axios
      .post(
        getApiUrl("/api/v1/clients/send/verification-code"),
        dataObj
      )
      .then((response) => {
        if (response.data.success) {
          successHandler(response.data.message);
        } else {
          setIsErrorAlert(true);
          setIsErrorAlertMessage(response.data.message);
        }
      })
      .catch((error) => {
        let message = error.message;
        if (error.response.status == 500) {
          message = "Server error. Please try again!";
        }
        setIsErrorAlertMessage(message);
        setIsErrorAlert(true);
      });
  };

  const successHandler = (message) => {
    setIsSuccessTryAgainAlert(true);
    setIsSuccessTryAgainAlertMessage(message);
  };

  const successVerifyHandler = (message) => {
    setIsSuccessAlert(true);
    setIsSuccessAlertMessage(message);
    localStorage.setItem("MyRTP", JSON.stringify({ SubjectID: hashId }));
    // const RTLoginEvent = new CustomEvent("RTLoginEvent", {
    //   detail: {
    //     SubjectID: hashId,
    //   },
    // });
    // window.document.dispatchEvent(RTLoginEvent);
    getUserHeardList(hashId);
  };

  const onVerifyCodeHandle = () => {
    setIsFirstSubmit(true);
    let isConfirm = !checkErrors();
    if (isConfirm) {
      const dataObj = { hash_id: hashId, verification_code: code };
      axios
        .post(
          getApiUrl("/api/v1/clients/validate/verification-code"),
          dataObj
        )
        .then((response) => {
          if (response.data.success) {
            successVerifyHandler(response.data.message);
          } else {
            setIsErrorAlert(true);
            setIsErrorAlertMessage(response.data.message);
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  };

  const closeHandler = () => {
    setIsConfirmMessageLogInForm(false);
    container.setAttribute("visible", "false");
  };

  return (
    <>
      {isBaseAlert && (
        <AlertMessage
          header="Error!"
          content={unConfirmMessage}
          onClick={() => setIsBaseAlert(false)}
        />
      )}
      {isSuccessTryAgainAlert && (
        <AlertMessage
          header="Success!"
          content={isSuccessTryAgainAlertMessage}
          onClick={() => setIsSuccessTryAgainAlert(false)}
        />
      )}
      {isSuccessAlert && (
        <AlertMessage
          header="Success!"
          logInSuccess={true}
          content={isSuccessAlertMessage}
          onClick={closeHandler}
        />
      )}
      {isErrorAlert && (
        <AlertMessage
          header="Error!"
          content={isErrorAlertMessage}
          onClick={() => {
            setIsErrorAlert(false);
          }}
        />
      )}
      {!isSuccessAlert && (
        <div className="confirm-message-logIn-wrapper">
          <div className="confirm-message-logIn-form-logo-wrapper">
            <RealtyTexasLogoIcon />
          </div>
          <div className="confirm-message-logIn-close" onClick={closeHandler}>
            <CloseIcon />
          </div>
          <div className="confirm-message-logIn-content">
            <div className="confirm-message-logIn-content-header">
              <span
                style={{ color: headerColor ? headerColor : colors.bodyText }}
              >
                {header}
              </span>
            </div>
            <div className="confirm-message-logIn-content-text-box">
              <div>
                <div className="confirm-message-logIn-content-text">
                  <span
                    style={{
                      color: contentColor ? contentColor : colors.bodyText,
                    }}
                  >
                    Please check your inbox and enter the code below.
                  </span>
                </div>
                <div className="confirm-message-logIn-content-text-bottom">
                  <span
                    onClick={onTryItHandle}
                    style={{
                      color: contentColor ? contentColor : colors.bodyText,
                    }}
                  >
                    Didn't receive an email? Try again
                  </span>
                </div>
                <div className="confirm-message-logIn-content-text-code">
                  <span
                    style={{
                      color: contentColor ? contentColor : colors.bodyText,
                    }}
                  >
                    4-digits code
                  </span>
                </div>
              </div>
              <div className="confirm-message-logIn-content-text-code-input-box">
                <BaseUnderlineInput
                  height="40px"
                  dark
                  className="confirm-message-logIn-content-text-code-input"
                  onChange={setCode}
                  value={code}
                  maxLength={4}
                />
                {codeError && (
                  <div className="confirm-message-logIn-content-form-input-error-2">
                    <div>
                      <ErrorIcon />
                    </div>
                    <div>
                      <p>{codeErrorText}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="confirm-message-logIn-content-text-submit">
                <BaseButton bold height="40px" onClick={onVerifyCodeHandle}>
                  Verify Code
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmMessageLogInForm;
