import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import axios from "axios";
import "./signOutForm.css";
import { getApiUrl } from "../../constants/methods";

const SignOutForm = ({
  setIsVisible,
  setIsSignOut,
  subjectID,
  userData,
  screenWidth = 0,
}) => {
    const  removeParameters=(url, paramsToRemove)=> {
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);

        paramsToRemove.forEach(paramToRemove => {
          searchParams.delete(paramToRemove);
        });

        urlObj.search = searchParams.toString();
        window.history.pushState({}, '', urlObj.toString());
        return urlObj.toString();
      }

  const signOutHandler = () => {
    localStorage.removeItem("listingInteractions");
    localStorage.removeItem("MyRTP");
    removeParameters(window.location.href,["IdentityAlias", "gateway"])
    const RTLogoutEvent = new CustomEvent("RTLogoutEvent", {
      detail: {
        SubjectID: subjectID,
      },
    });

    window.document.dispatchEvent(RTLogoutEvent);
    setIsSignOut(false);
    setIsVisible("false");
    axios.get(getApiUrl("/sign-out"))
  };

  const [headerHeight, setHeaderHeight] = useState(null);
  const [headerPaddingRight, setHeaderPaddingRight] = useState(null);
  const [headerMarginRight, setHeaderMarginRight] = useState(null);
  const [headerRight, setHeaderRight] = useState(null);

  useEffect(() => {
    const headerDiv = document?.getElementById("header-wrapper");
    const headerDivSub = document?.getElementById("header-content-wrapper");
    if (headerDiv) {
      setHeaderHeight(headerDiv?.clientHeight);
      const computedStyle = window?.getComputedStyle(headerDiv);
      const computedStyleSub = window?.getComputedStyle(headerDivSub);
      setHeaderPaddingRight(parseInt(computedStyle?.paddingRight, 10));
      setHeaderMarginRight(parseInt(computedStyleSub?.marginRight, 10));
    }
  }, [screenWidth]);

  useEffect(() => {
    setHeaderRight(+headerPaddingRight + +headerMarginRight);
  }, [headerPaddingRight, headerMarginRight]);
  // const computedStyle = window.getComputedStyle(headerDiv);
  // const headerPaddingRight = parseInt(computedStyle.paddingRight, 10);
  return (
    <div
      className="signOut-box-wrapper"
      style={{
        top: headerHeight ? headerHeight : 60,
        right: headerRight ? headerRight : 0,
      }}
    >
      <div className="signOut-box-wrapper-header">
        <div className="signOut-box-wrapper-header-company">
          Realty Texas, LLC
        </div>
        <div
          className="signOut-box-wrapper-header-sign-out"
          onClick={signOutHandler}
        >
          Sign out
        </div>
      </div>
      <div className="signOut-box-wrapper-content">
        <div
          className="signOut-box-wrapper-content-avatar"
          style={{
            backgroundImage: userData?.image_url
              ? `url('${userData?.image_url}')`
              : "url('https://rtcdn2.azureedge.net/public/assets/images/customer.png')",
          }}
        ></div>
        <div className="signOut-box-wrapper-content-main">
          <div className="signOut-box-wrapper-content-main-name">
            <p>{userData?.PartyName ?? "-"}</p>
          </div>
          <div className="signOut-box-wrapper-content-main-email">
            <p>{userData?.PartyEmail ?? "-"}</p>
          </div>
          <div className="signOut-box-wrapper-content-main-link">
            <a href={'/clients/dashboard'}>View Dashboard</a>
          </div>
          <div className="signOut-box-wrapper-content-main-link">
            <a
              href={`/client/gateway?IdentityAlias=${subjectID}`}
              target="_blank"
            >
              My Portal
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignOutForm;
