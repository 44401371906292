import React, { useEffect, useState } from "react";
import {
  CloseEyeIcon,
  CloseIcon,
  ErrorIcon,
  OpenEyeIcon,
  RealtyTexasLogoIcon,
} from "../../constants/icons";
import { BaseInput, BaseInputList } from "../../ui";
import { BaseButton } from "../../ui/Buttons";
import "./signUpForm.css";
import "./responsive.css";
import axios from "axios";
import { colors } from "../../constants/colors";
import ReCAPTCHA from "react-google-recaptcha";
import AlertMessage from "../AlertMessage/AlertMessage";
import { useScreenSize, UseValidateEmail } from "../../hooks";
import ConfirmMessage from "../ConfirmMessage/ConfirmMessage";
import {getApiUrl} from "../../constants/methods";

const listMetroMocked = [
  { label: "Select a Metro", value: null },
  { label: "Texas", value: "Texas" },
  { label: "Abilene", value: "Abilene" },
  { label: "Amarillo", value: "Amarillo" },
  { label: "Austin", value: "Austin" },
  { label: "Beaumont-Port Arthur", value: "Beaumont-Port Arthur" },
  { label: "Brownsville-Harlingen", value: "Brownsville-Harlingen" },
  { label: "College Station-Bryan", value: "College Station-Bryan" },
  { label: "Corpus Christi", value: "Corpus Christi" },
  { label: "Dallas-Plano-Irving", value: "Dallas-Plano-Irving" },
  { label: "El Paso", value: "El Paso" },
  { label: "Fort Worth-Arlington", value: "Fort Worth-Arlington" },
  { label: "Houston", value: "Houston" },
  { label: "Killeen-Temple", value: "Killeen-Temple" },
  { label: "Laredo", value: "Laredo" },
  { label: "Longview", value: "Longview" },
  { label: "Lubbock", value: "Lubbock" },
  { label: "McAllen", value: "McAllen" },
  { label: "Midland", value: "Midland" },
  { label: "Odessa", value: "Odessa" },
  { label: "San Angelo", value: "San Angelo" },
  { label: "San Antonio", value: "San Antonio" },
  { label: "Sherman-Dension", value: "Sherman-Dension" },
  { label: "Texarkana", value: "Texarkana" },
  { label: "Tyler", value: "Tyler" },
  { label: "Victoria", value: "Victoria" },
  { label: "Waco", value: "Waco" },
  { label: "Wichita Falls", value: "Wichita Falls" },
];

const listPropertyTypeMocked = [
  { label: "Select Type", value: null },
  { label: "Condo", value: "Condo" },
  { label: "Farm", value: "Farm" },
  { label: "Home", value: "Home" },
  { label: "Land", value: "Land" },
];

const SignInForm = ({
  container,
  setIsLoginForm,
  setIsSignUp,
  setHashId,
  hashId,
  setIsConfirmMessage,
  isConfirmMessage,
  setEmail,
  email,
}) => {
  const backHandler = () => {
    setIsSignUp(false);
    setIsLoginForm(true);
  };

  const closeHandler = () => {
    // setIsSignUp(false);
    container.setAttribute("visible", "false");
    setIsSuccessAlert(false);
  };

  const closeSuccessHandler = () => {
    setIsSignUp(false);
  };

  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isErrorAlertMessage, setIsErrorAlertMessage] =
    useState("Base error text");
  const [isSuccessAlertMessage, setIsSuccessAlertMessage] =
    useState("Base error text");

  return (
    <>
      {isSuccessAlert && (
        <AlertMessage
          header="Success!"
          content={isSuccessAlertMessage}
          onClick={closeSuccessHandler}
        />
      )}
      {isErrorAlert && (
        <AlertMessage
          header="Error!"
          content={isErrorAlertMessage}
          onClick={() => {
            setIsErrorAlert(false);
          }}
        />
      )}
      {!isSuccessAlert && (
        <div className="sign-up-form-wrapper">
          <div className="sign-up-form-logo-wrapper">
            <RealtyTexasLogoIcon />
          </div>
          <div
            className="sign-up-form-close-icon-wrapper"
            onClick={closeHandler}
          >
            <CloseIcon />
          </div>
          <div className="sign-up-form-content-wrapper">
            <div className="sign-up-form-content-title">
              Create your profile now
            </div>
            <div className="sign-up-form-content-box">
              <SignInFormContentForm
                setIsSuccessAlert={setIsSuccessAlert}
                setIsErrorAlert={setIsErrorAlert}
                setIsErrorAlertMessage={setIsErrorAlertMessage}
                setIsSuccessAlertMessage={setIsSuccessAlertMessage}
                setIsSignUp={setIsSignUp}
                setHashId={setHashId}
                setEmail={setEmail}
                email={email}
                setIsConfirmMessage={setIsConfirmMessage}
              />
              <div className="sign-up-form-alternative-block">
                <div>
                  <span>Already have an account?</span>
                </div>
                <div className="sign-up-form-alternative-block-back">
                  <span onClick={backHandler}>Login</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignInForm;

const SignInFormContentForm = ({
  setIsSuccessAlert,
  setIsErrorAlert,
  setIsErrorAlertMessage,
  setIsSuccessAlertMessage,
  setIsSignUp,
  setHashId,
  email,
  setEmail,
  setIsConfirmMessage,
}) => {
  const [captcha, setCaptcha] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [propertyType, setPropertyType] = useState({
    label: "Select Type",
    value: null,
  });
  const [lookingIn, setLookingIn] = useState({
    label: "Select a Metro",
    value: null,
  });
  const [lookingTo, setLookingTo] = useState({
    label: "buy",
    value: 0,
  });

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorText, setCaptchaErrorText] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [chaptchaScale, setChaptchaScale] = useState(1.06);
  const [marginScale, setMarginScale] = useState(0);
  const [password1, setPassword1] = useState("");
  const [passwordError1, setPasswordError1] = useState(false);
  const [passwordErrorText1, setPasswordErrorText1] = useState(false);
  const [isVisiblePassword1, setIsVisiblePassword1] = useState(false);

  const [ConfirmPasword, setConfirmPasword] = useState("");
  const [ConfirmPaswordError, setConfirmPaswordError] = useState(false);
  const [ConfirmPaswordErrorText, setConfirmPaswordErrorText] = useState(false);
  const [isVisibleConfirmPasword, setIsVisibleConfirmPasword] = useState(false);

  const isNumber = (val) => {
    const reg = new RegExp("^[0-9]+$");
    return !reg.test(val);
  };

  const checkErrors = () => {
    //email valid
    let result1 = false;
    let result2 = false;
    let result3 = false;
    let result4 = false;
    let result5 = false;
    let result6 = false;
    if (email === "") {
      setEmailError((prev) => true);
      setEmailErrorText("Required!");
      result1 = true;
    } else if (!UseValidateEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Should has email type!");
      result1 = true;
    } else {
      setEmailError((prev) => false);
    }
    //name valid
    if (name.length === 0) {
      setNameError((prev) => true);
      setNameErrorText("Required!");
      result2 = true;
    } else if (name !== "" && name.split(/\W+/).length > 2) {
      setNameError(true);
      setNameErrorText("Only first name and second name are required!");
      result2 = true;
    } else if (name !== "" && name.split(/\W+/).length !== 2) {
      setNameError(true);
      setNameErrorText("First name and second name are required!");
      result2 = true;
    } else if (name !== "" && name.length < 7) {
      setNameError(true);
      setNameErrorText("Should be more letters in names!");
      result2 = true;
    } else {
      setNameError((prev) => false);
    }
    //phone valid
    if (phoneNumber === "") {
      setPhoneNumberError((prev) => true);
      setPhoneNumberErrorText("Required!");
      result3 = true;
    } else if (isNumber(phoneNumber)) {
      setPhoneNumberError(true);
      setPhoneNumberErrorText("Numbers only required!");
      result3 = true;
    } else if (phoneNumber.length !== 10) {
      setPhoneNumberError(true);
      setPhoneNumberErrorText("Ten numbers only required!");
      result3 = true;
    } else {
      setPhoneNumberError((prev) => false);
    }
    //valid password
    if (password1 === "") {
      setPasswordError1((prev) => true);
      setPasswordErrorText1("Required!");
      result4 = true;
    } else if (password1.length < 8) {
      setPasswordError1((prev) => true);
      setPasswordErrorText1("Should be that 8 letters!");
      result4 = true;
    } else if (password1 !== ConfirmPasword) {
      setPasswordError1((prev) => true);
      setPasswordErrorText1("Should be the same passwords!");
      result4 = true;
    } else {
      setPasswordError1((prev) => false);
    }
    if (ConfirmPasword === "") {
      setConfirmPaswordError((prev) => true);
      setConfirmPaswordErrorText("Required!");
      result4 = true;
    } else if (ConfirmPasword.length < 8) {
      setConfirmPaswordError((prev) => true);
      setConfirmPaswordErrorText("Should be that 8 letters");
      result4 = true;
    } else if (ConfirmPasword !== password1) {
      setConfirmPaswordError((prev) => true);
      setConfirmPaswordErrorText("Should be the same passwords!");
      result4 = true;
    } else {
      setConfirmPaswordError((prev) => false);
    }
    //valid captcha
    if (captcha === null) {
      setCaptchaError((prev) => true);
      setCaptchaErrorText("Required!");
      result6 = true;
    } else {
      setCaptchaError((prev) => false);
    }
    if (!result1 && !result2 && !result3 && !result4 && !result5 && !result6) {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (isFirstSubmit) checkErrors();
  }, [
    email,
    name,
    phoneNumber,
    captcha,
    ConfirmPasword,
    password1,
    isFirstSubmit,
  ]);

  const isSubmitConfirm = () => {
    let result = !checkErrors();
    return result;
  };
  const dataObj = {
    email: email,
    password: password1,
    "g-recaptcha-response": captcha,
  };

  const successHandler = (data, message = "") => {
    // localStorage.setItem(
    //   "MyRTP",
    //   JSON.stringify({ SubjectID: data["g-recaptcha-response"] })
    // );
    // setIsSignUp(false);
    setIsSuccessAlert(true);
    setIsSuccessAlertMessage(message);
    setIsConfirmMessage(true);

    // const RTLoginEvent = new CustomEvent("RTLoginEvent", {
    //   detail: {
    //     SubjectID: data,
    //   },
    // });
    // window.document.dispatchEvent(RTLoginEvent);
  };

  const submitHandler = () => {
    setIsFirstSubmit(true);
    let isConfirm = isSubmitConfirm();
    const data = {
      clientrole: null,
      realtor: null,
      language: null,
      houseToSell: 0,
      searchcity: null,
      metro: lookingIn.value === null ? null : lookingIn.value,
      PartyName: name,
      PartyPhone: phoneNumber.toString(),
      PartyEmail: email,
      fname: name?.split(" ")[0],
      lname: name?.split(" ")[1],
      selectedProperty: lookingTo.label,
      cproperty: propertyType.value === null ? null : propertyType.value,
      Password: password1,
      ConfirmPasword: ConfirmPasword,
      "g-recaptcha-response": captcha,
    };
    if (isConfirm) {
      axios
        .post(getApiUrl("/api/v1/clients/sign-up"), data)
        .then((response) => {
          if (response.data.success) {
            successHandler(dataObj, response.data.message);
            setHashId(response.data?.hash_id);
          } else {
            setIsErrorAlertMessage(response.data.message);
            setIsErrorAlert(true);
          }
        })
        .catch((error) => {
          let message = error.message;
          // if (error.response.status == 500) {
          if (error?.status == 500 || error?.response?.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  };

  const lookingToHandler = (label, value) => {
    setLookingTo({
      label: label,
      value: value,
    });
  };

  const recaptchaRef = React.useRef();

  function onChangeCaptcha(value) {
    setCaptcha(value);
  }

  const screenSize = useScreenSize();

  const getChaptchaScale = () => {
    if (screenSize[0] <= 360) {
      let x = (screenSize[0] - 40) / 304;
      setChaptchaScale(x);
    }
  };

  const getMarginScale = () => {
    if (screenSize[0] <= 360) {
      let x = (360 - screenSize[0]) * 0.225;
      setMarginScale(x);
    }
  };

  useEffect(() => {
    getChaptchaScale();
    getMarginScale();
  }, [screenSize[0]]);

  const hiddenPassword1 = () => {
    setIsVisiblePassword1(!isVisiblePassword1);
  };

  const hiddenConfirmPasword = () => {
    setIsVisibleConfirmPasword(!isVisibleConfirmPasword);
  };

  return (
    <form method="dialog" className="sign-up-form-content-form">
      <div className="sign-up-form-content-form-box">
        <div className="sign-up-form-content-form-looking-to form-box-title">
          <div>
            <span>Are you looking to</span>
          </div>
          <div className="sign-up-form-content-form-looking-to-box">
            <div
              className="sign-up-form-content-form-looking-to-div"
              style={{
                backgroundColor:
                  lookingTo.value === 0 ? colors.bodyText : colors.secondBg,
                color:
                  lookingTo.value === 0 ? colors.secondBg : colors.bodyText,
              }}
              onClick={() => lookingToHandler("buy", 0)}
            >
              <div>Buy</div>
            </div>
            <div
              className="sign-up-form-content-form-looking-to-div"
              style={{
                backgroundColor:
                  lookingTo.value === 1 ? colors.bodyText : colors.secondBg,
                color:
                  lookingTo.value === 1 ? colors.secondBg : colors.bodyText,
              }}
              onClick={() => lookingToHandler("sell", 1)}
            >
              <div>Sell</div>
            </div>
            <div
              className="sign-up-form-content-form-looking-to-div"
              style={{
                backgroundColor:
                  lookingTo.value === 2 ? colors.bodyText : colors.secondBg,
                color:
                  lookingTo.value === 2 ? colors.secondBg : colors.bodyText,
              }}
              onClick={() => lookingToHandler("lease", 2)}
            >
              <div>Lease</div>
            </div>
            <div
              className="sign-up-form-content-form-looking-to-div"
              style={{
                backgroundColor:
                  lookingTo.value === 3 ? colors.bodyText : colors.secondBg,
                color:
                  lookingTo.value === 3 ? colors.secondBg : colors.bodyText,
              }}
              onClick={() => lookingToHandler("unsure", 3)}
            >
              <div>Unsure</div>
            </div>
          </div>
        </div>
        <div className="sign-up-form-content-form-looking-in form-box-title">
          <div>
            <span>What metro are you looking in?</span>
          </div>
          <BaseInputList
            dark={true}
            className="filters-block-filter-details-input-list-block-input-gray-color"
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={listMetroMocked}
            placeholder="Select a Metro"
            listTop="49px"
            field="label"
            inputValue={lookingIn}
            setInputValue={setLookingIn}
            height={50}
            colorEndIcon={colors.bodyText}
          />
        </div>
        <div className="sign-up-form-content-form-property-type form-box-title">
          <div>
            <span>Property Type?</span>
          </div>
          <BaseInputList
            dark={true}
            className="filters-block-filter-details-input-list-block-input-gray-color"
            classNameList="filters-block-filter-details-input-list-block-input-gray-color"
            readonly={true}
            list={listPropertyTypeMocked}
            placeholder="Select Type"
            listTop="49px"
            field="label"
            inputValue={propertyType}
            setInputValue={setPropertyType}
            height={50}
            colorEndIcon={colors.bodyText}
          />
        </div>
        <div className="sign-up-form-content-form-contact-info form-box-title">
          <div>
            <span>Contact Info</span>
          </div>
          <div className="sign-up-content-form-input-wrapper">
            <BaseInput
              dark
              placeholder="Name"
              className="sign-up-form-content-form-email"
              onChange={setName}
              value={name}
              borderColor={nameError ? colors.red : colors.gray}
              borderWeight={nameError ? "2px" : "1px"}
            />
            {nameError && (
              <div className="sign-up-content-form-input-error">
                <div>
                  <ErrorIcon />
                </div>
                <div>
                  <p>{nameErrorText}</p>
                </div>
              </div>
            )}
          </div>
          <div className="sign-up-content-form-input-wrapper">
            <BaseInput
              dark
              placeholder="Phone Number"
              className="sign-up-form-content-form-email"
              onChange={setPhoneNumber}
              value={phoneNumber}
              borderColor={phoneNumberError ? colors.red : colors.gray}
              borderWeight={phoneNumberError ? "2px" : "1px"}
            />
            {phoneNumberError && (
              <div className="sign-up-content-form-input-error">
                <div>
                  <ErrorIcon />
                </div>
                <div>
                  <p>{phoneNumberErrorText}</p>
                </div>
              </div>
            )}
          </div>
          <div
            className="sign-up-content-form-input-wrapper"
            style={{ marginBottom: emailError ? "22px" : "0px" }}
          >
            <BaseInput
              dark
              placeholder="Email"
              className="sign-up-form-content-form-email"
              onChange={setEmail}
              value={email}
              borderColor={emailError ? colors.red : colors.gray}
              borderWeight={emailError ? "2px" : "1px"}
            />
            {emailError && (
              <div className="sign-up-content-form-input-error">
                <div>
                  <ErrorIcon />
                </div>
                <div>
                  <p>{emailErrorText}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sign-up-form-content-form-password-info form-box-title">
          <div>
            <span>Password</span>
          </div>
          <div
            className="sign-up-content-form-input-wrapper"
            style={{ marginBottom: passwordError1 ? "22px" : "0px" }}
          >
            <BaseInput
              dark
              placeholder="Password"
              className="sign-up-content-form-password"
              onChange={setPassword1}
              value={password1}
              borderColor={passwordError1 ? colors.red : colors.gray}
              borderWeight={passwordError1 ? "2px" : "1px"}
              labelEndIcon={
                isVisiblePassword1 ? <OpenEyeIcon /> : <CloseEyeIcon />
              }
              onClickLabelEndIcon={hiddenPassword1}
              type={isVisiblePassword1 ? "text" : "password"}
              maxLength={16}
            />
            {passwordError1 && (
              <div className="sign-up-content-form-input-error">
                <div>
                  <ErrorIcon />
                </div>
                <div>
                  <p>{passwordErrorText1}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sign-up-form-content-form-confirm-password-info form-box-title">
          <div>
            <span>Confirm Password</span>
          </div>
          <div className="sign-up-content-form-input-wrapper">
            <BaseInput
              dark
              placeholder="Password"
              className="sign-up-content-form-ConfirmPasword"
              onChange={setConfirmPasword}
              value={ConfirmPasword}
              borderColor={ConfirmPaswordError ? colors.red : colors.gray}
              borderWeight={ConfirmPaswordError ? "2px" : "1px"}
              labelEndIcon={
                isVisibleConfirmPasword ? <OpenEyeIcon /> : <CloseEyeIcon />
              }
              onClickLabelEndIcon={hiddenConfirmPasword}
              type={isVisibleConfirmPasword ? "text" : "password"}
              maxLength={16}
            />
            {ConfirmPaswordError && (
              <div className="sign-up-content-form-input-error">
                <div>
                  <ErrorIcon />
                </div>
                <div>
                  <p>{ConfirmPaswordErrorText}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="login-form-content-form-input-wrapper">
        <ReCAPTCHA
          style={{
            transform:
              screenSize[0] >= 360 ? "scale(1.06)" : `scale(${chaptchaScale})`,
            transformOrigin: "0 0",
          }}
          ref={recaptchaRef}
          sitekey="6LdaE30jAAAAAEbA7bEuPajVMcMnsUb8wddFThI0"
          onChange={onChangeCaptcha}
          theme="dark"
        />
        {captchaError && (
          <div
            className="login-form-content-form-input-error-captcha"
            style={{
              top: screenSize[0] < 360 ? `${82 - marginScale}px` : "82px",
            }}
          >
            <div>
              <ErrorIcon />
            </div>
            <div>
              <p>{captchaErrorText}</p>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: screenSize[0] < 360 ? `-${marginScale}px` : 0 }}>
        <BaseButton bold height="40px" onClick={submitHandler}>
          Sign Up
        </BaseButton>
      </div>
    </form>
  );
};
