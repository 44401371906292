import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import {
  CloseEyeIcon,
  CloseIcon,
  ErrorIcon,
  OpenEyeIcon,
  RealtyTexasLogoIcon,
} from "../../constants/icons";
import { BaseInput } from "../../ui";
import { BaseButton } from "../../ui/Buttons";
import axios from "axios";
import "./logInForm.css";
import "./responsive.css";
import ReCAPTCHA from "react-google-recaptcha";
import AlertMessage from "../AlertMessage/AlertMessage";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useScreenSize, UseValidateEmail } from "../../hooks";
import { getUserHeardList } from "../../constants/functions";
import {getApiUrl} from "../../constants/methods";

const LogInForm = ({
  container,
  setIsConfirmMessageLogInForm,
  setUnConfirmMessage,
  setIsResetPass,
  setIsLoginForm,
  setIsSignUp,
  setEmail,
  setHashId,
  isSuccessSubdomain
}) => {
  const title = container.getAttribute("logIn-title");
  const closeHandler = () => {
    setIsLoginForm(false);
    container.setAttribute("visible", "false");
  };

  const signUpHandler = () => {
    setEmail("");
    setIsLoginForm(false);
    setIsSignUp(true);
  };

  const [isSuccessAlert, setIsSuccessAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isErrorAlertMessage, setIsErrorAlertMessage] =
    useState("Base error text");
  const [isSuccessAlertMessage, setIsSuccessMessage] =
    useState("Base error text");
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [googleData, setGoogleData] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => {
      setIsErrorAlert(true);
      setIsErrorAlertMessage(error?.message);
    },
  });

  useEffect(() => {
    if (user?.access_token && profile?.id) {
      setGoogleData({
        access_token: user.access_token,
        token_type: user.token_type,
        email: profile.email,
        name: profile.name,
        first_name: profile.given_name,
        second_name: profile.family_name,
      });
    }
  }, [user, profile]);

  const googleFacebookSuccessHandler = (data, message) => {
    setIsSuccessAlert(true);
    setIsSuccessMessage(message);
    localStorage.setItem("MyRTP", JSON.stringify({ SubjectID: data }));
    // const RTLoginEvent = new CustomEvent("RTLoginEvent", {
    //   detail: {
    //     SubjectID: data,
    //   },
    // });
    // window.document.dispatchEvent(RTLoginEvent);
    getUserHeardList(data);
  };

  //if google success
  useEffect(() => {
    if (googleData?.access_token) {
      axios
        .post(
            getApiUrl(`/api/v1/clients/facebook/sign-in/Google`),
          googleData,
          {
            headers: {
              Authorization: `Bearer ${googleData.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            googleFacebookSuccessHandler(
              res?.data?.Client?.HashID,
              res?.data?.message
            );
          } else {
            setIsErrorAlert(true);
            setIsErrorAlertMessage(res.data.message);
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  }, [googleData?.access_token]);

  useEffect(() => {
    if (user?.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((error) => {
          let message = error.message;
          if (error.response.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  }, [user?.access_token]);

  const [facebookData, setFacebookData] = useState({});

  //facebook response
  const responseFacebook = (response) => {
    if (response.accessToken) {
      response.access_token = response.accessToken;
      setFacebookData(response);
    } else {
      setIsErrorAlert(true);
      setIsErrorAlertMessage("Something go wrong! Try again, please.");
    }
  };

  //if facebook success
  useEffect(() => {
    if (facebookData?.accessToken) {
      axios
        .post(
          `/api/v1/clients/facebook/sign-in/Facebook`,
          facebookData,
          {
            headers: {
              Authorization: `Bearer ${facebookData.accessToken}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            googleFacebookSuccessHandler(
              res?.data?.Client?.HashID,
              res?.data?.message
            );
          } else {
            setIsErrorAlert(true);
            setIsErrorAlertMessage(res.data.message);
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response.status == 500) {
            message = "Server error. Please try again!";
          }
          setIsErrorAlertMessage(message);
          setIsErrorAlert(true);
        });
    }
  }, [facebookData?.accessToken]);

  return (
    <>
      {isSuccessAlert && (
        <AlertMessage
          header="Success!"
          logInSuccess={true}
          content={isSuccessAlertMessage}
          onClick={() => {
            setIsSuccessAlert(false);
            closeHandler();
          }}
        />
      )}
      {isErrorAlert && (
        <AlertMessage
          header="Error!"
          content={isErrorAlertMessage}
          onClick={() => {
            setIsErrorAlert(false);
          }}
        />
      )}
      {!isSuccessAlert && (
        <div className="login-form-wrapper">
          <div className="login-form-logo-wrapper">
            <RealtyTexasLogoIcon />
          </div>
          <div className="login-form-close-icon-wrapper" onClick={closeHandler}>
            <CloseIcon />
          </div>
          <div className="login-form-content-wrapper">
            <div className="login-form-content-title">{title}</div>
            <LogInContentForm
              container={container}
              setIsResetPass={setIsResetPass}
              setIsLoginForm={setIsLoginForm}
              setIsSuccessAlert={setIsSuccessAlert}
              setIsErrorAlert={setIsErrorAlert}
              setIsErrorAlertMessage={setIsErrorAlertMessage}
              setIsSuccessMessage={setIsSuccessMessage}
              setIsConfirmMessageLogInForm={setIsConfirmMessageLogInForm}
              setUnConfirmMessage={setUnConfirmMessage}
              setHashId={setHashId}
            />
            <div className="login-form-content-or">OR</div>
            <div className="login-form-alternative-block">
              {/* <BaseButton bold height="40px">
                Continue with Facebook
              </BaseButton> */}
            <div className="login-form-alternative-block" style={{display: isSuccessSubdomain ? 'flex' : 'none'}}>
              <FacebookLogin
                appId="827173071593439"
                autoLoad={false}
                // fields="name,email,picture"
                // scope="public_profile,user_friends"
                callback={responseFacebook}
                // icon="fa-facebook"
                // fields="name,email,picture"
                disableMobileRedirect={true}
                render={(renderProps) => (
                  <BaseButton bold height="40px" onClick={renderProps.onClick}>
                    Continue with Facebook
                  </BaseButton>
                )}
              />
              <BaseButton bold height="40px" onClick={() => login()}>
                Continue with Google
              </BaseButton>
            </div>
              <div className="login-form-alternative-block-need-acc">
                <div>
                  <span>Need an account?</span>
                </div>
                <div>
                  <span
                    className="login-form-alternative-block-sign-up"
                    onClick={signUpHandler}
                  >
                    Sign Up
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogInForm;

const LogInContentForm = ({
  container,
  setIsResetPass,
  setIsLoginForm,
  setIsSuccessAlert,
  setIsErrorAlert,
  setIsErrorAlertMessage,
  setIsSuccessMessage,
  setIsConfirmMessageLogInForm,
  setUnConfirmMessage,
  setHashId,
}) => {
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [password, setPassword] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorText, setCaptchaErrorText] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [chaptchaScale, setChaptchaScale] = useState(1.06);
  const [marginScale, setMarginScale] = useState(0);

  const resetPassHandler = () => {
    setIsLoginForm(false);
    setIsResetPass(true);
  };

  const checkErrors = () => {
    let result1 = false;
    let result2 = false;
    let result3 = false;
    //valid email
    if (email === "") {
      setEmailError((prev) => true);
      setEmailErrorText("Required!");
      result1 = true;
    } else if (!UseValidateEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Should has email type!");
      result1 = true;
    } else {
      setEmailError((prev) => false);
    }
    //valid password
    if (password === "") {
      setPasswordError((prev) => true);
      setPasswordErrorText("Required!");
      result2 = true;
    } else if (password.length < 8) {
      setPasswordError((prev) => true);
      setPasswordErrorText("Should be that 8 letters");
      result2 = true;
    } else {
      setPasswordError((prev) => false);
    }
    //valid captcha
    if (captcha === null) {
      setCaptchaError((prev) => true);
      setCaptchaErrorText("Required!");
      result3 = true;
    } else {
      setCaptchaError((prev) => false);
    }
    if (!result1 && !result2 && !result3) {
      return false;
    } else return true;
  };

  useEffect(() => {
    if (isFirstSubmit) checkErrors();
  }, [email, password, captcha, isFirstSubmit]);

  const isSubmitConfirm = () => {
    return !checkErrors();
  };

  const submitHandler = () => {
    setIsFirstSubmit(true);
    let isConfirm = isSubmitConfirm();
    if (isConfirm) {
      const dataObj = {
        email: email,
        password: password,
        "g-recaptcha-response": captcha,
      };

      sendData(dataObj);
    }
  };

  const sendData = (data) => {
    const confirmHandler = (id, message) => {
      setIsConfirmMessageLogInForm(true);
      setUnConfirmMessage(message);
      setHashId(id);
      setIsLoginForm(false);
    };
    const successHandler = (data, message = "") => {
      // setIsLoginForm(false);
      // container.setAttribute("visible", "false");
      // localStorage.setItem("user", JSON.stringify({ HashID: data }));
      // localStorage.setItem("authUser", JSON.stringify({ HashID: data }));
      localStorage.setItem("MyRTP", JSON.stringify({ SubjectID: data }));
      // localStorage.setItem("isLogin", true);
      setIsSuccessMessage(message);
      setIsSuccessAlert(true);

      const RTLoginEvent = new CustomEvent("RTLoginEvent", {
        detail: {
          SubjectID: data,
        },
      });
      window.document.dispatchEvent(RTLoginEvent);
      getUserHeardList(data);
    };

    axios
      .post(getApiUrl("/api/v1/clients/sign-in"), data)
      .then((response) => {
        if (response.data.success) {
          successHandler(response.data.Client.HashID, response.data.message);
          fetch('/me', {
                method: 'GET',
                credentials: 'include',
            })
            .then(response => {
                if(window.afterLoginCall){
                    try {
                      window.afterLoginCall();
                      delete window.afterLoginCall
                    } catch (error) {

                    }

                }
                return response.json()
            })
            .catch(error => console.error('Error:', error));
        } else if (
          response.data.success === false &&
          response.data.Client &&
          response.data.Client.HashID !== undefined
        ) {
          confirmHandler(response.data.Client.HashID, response.data.message);
          resetReCAPTCHA();
        } else {
          setIsErrorAlert(true);
          setIsErrorAlertMessage(response.data.message);
          resetReCAPTCHA();
        }
      })
      .catch((error) => {
          resetReCAPTCHA();
        let message = error.message;
        if (error?.response?.status == 500) {
          message = "Server error. Please try again!";
        }
        setIsErrorAlertMessage(message);
        setIsErrorAlert(true);
      });
  };

  const recaptchaRef = React.useRef();

  function onChangeCaptcha(value) {
    setCaptcha(value);
  }

  const resetReCAPTCHA = () => {
    // Check if the ref is available
    if (recaptchaRef.current) {
      // Call the reset method on the ref
      recaptchaRef.current.reset();
    }
  };
  const screenSize = useScreenSize();

  const hiddenPassword = () => {
    setIsVisiblePassword(!isVisiblePassword);
  };

  const getChaptchaScale = () => {
    if (screenSize[0] <= 360) {
      let x = (screenSize[0] - 40) / 304;
      setChaptchaScale(x);
    }
  };

  const getMarginScale = () => {
    if (screenSize[0] <= 360) {
      let x = (360 - screenSize[0]) * 0.225;
      setMarginScale(x);
    }
  };

  useEffect(() => {
    getChaptchaScale();
    getMarginScale();
  }, [screenSize[0]]);

  return (
    <form method="dialog" className="login-form-content-form">
      <div className="login-form-content-form-input-wrapper">
        <BaseInput
          dark
          type="text"
          placeholder="Email"
          className="login-form-content-form-email"
          onChange={setEmail}
          value={email}
          borderColor={emailError ? colors.red : colors.gray}
          borderWeight={emailError ? "2px" : "1px"}
        />
        {emailError && (
          <div className="login-form-content-form-input-error">
            <div>
              <ErrorIcon />
            </div>
            <div>
              <p>{emailErrorText}</p>
            </div>
          </div>
        )}
      </div>
      <div className="login-form-content-form-input-wrapper">
        <BaseInput
          dark
          placeholder="Password"
          className="login-form-content-form-password"
          onChange={setPassword}
          value={password}
          borderColor={passwordError ? colors.red : colors.gray}
          borderWeight={passwordError ? "2px" : "1px"}
          labelEndIcon={isVisiblePassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
          onClickLabelEndIcon={hiddenPassword}
          type={isVisiblePassword ? "text" : "password"}
          maxLength={16}
        />
        {passwordError && (
          <div className="login-form-content-form-input-error">
            <div>
              <ErrorIcon />
            </div>
            <div>
              <p>{passwordErrorText}</p>
            </div>
          </div>
        )}
      </div>
      <div className="login-form-content-form-forgot-password">
        <span onClick={resetPassHandler}>Forgot your password?</span>
      </div>
      <div className="login-form-content-form-input-wrapper">
        <ReCAPTCHA
          style={{
            transform:
              screenSize[0] >= 360 ? "scale(1.06)" : `scale(${chaptchaScale})`,
            transformOrigin: "0 0",
          }}
          ref={recaptchaRef}
          sitekey="6LdaE30jAAAAAEbA7bEuPajVMcMnsUb8wddFThI0"
          onChange={onChangeCaptcha}
          theme="dark"
        />
        {captchaError && (
          <div
            className="login-form-content-form-input-error-captcha"
            style={{
              top: screenSize[0] < 360 ? `${82 - marginScale}px` : "82px",
            }}
          >
            <div>
              <ErrorIcon />
            </div>
            <div>
              <p>{captchaErrorText}</p>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: screenSize[0] < 360 ? `-${marginScale}px` : 0 }}>
        <BaseButton bold height="40px" onClick={submitHandler} type="submit">
          Log in
        </BaseButton>
      </div>
    </form>
  );
};
